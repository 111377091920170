import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Commande } from 'src/app/core/models/commande.model';
import { CommandeService } from 'src/app/core/services/entities/commande.service';
import { LoaderService } from 'src/app/core/services/loader.service';

@Component({
  selector: 'app-terminer-commande-modal',
  templateUrl: './terminer-commande-modal.component.html',
  styleUrls: ['./terminer-commande-modal.component.scss']
})
export class TerminerCommandeModalComponent implements OnInit {

  commande: Commande;

  terminerForm: FormGroup;

  submit = false;

  loading$ = this.loader.loading$;

  statut;

  @Output()
  statusChange : EventEmitter<string> = new EventEmitter<string>();

  @Output()
  retirerEvent : EventEmitter<number> = new EventEmitter<number>();

  @Output()
  commandeChange : EventEmitter<Commande> = new EventEmitter<Commande>();

  today = (new Date()).toISOString().substring(0,10);

  constructor(public activeModal: NgbActiveModal, private service: CommandeService, public formBuilder: FormBuilder,
    private loader: LoaderService
  ) { }

  ngOnInit(): void {

    this.terminerForm = this.formBuilder.group({
      id: this.commande.id,
      date: (new Date()).toISOString().substring(0,10),
      statut: this.statut
    });
  }


  changeStatutCommande() {
    this.submit = true;


    if (!this.terminerForm.invalid) {

      let body = this.terminerForm.value;

      console.log(body);

      

      this.service.changeStatut(body).subscribe(
        data => {

          this.retirerEvent.emit(this.commande.id);
          this.commandeChange.emit(data);
          if(this.statut=='TERMINEE'){
            this.statusChange.emit("terminer");
          } else if(this.statut=='LIVREE'){
            this.statusChange.emit("livrer");
          }
         
          this.loader.hide();

          this.activeModal.dismiss(); 

        },
        error => {

          console.log(error);
          this.loader.hide();
        }
      );

      

    }
  }

  get form() {
    return this.terminerForm.controls;
  }

}
