import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from "src/environments/environment";
import { Commande } from '../../models/commande.model';
import { StatsCommandeMois } from '../../models/stats-commande-mois.model';



@Injectable({ providedIn: 'root' })
export class CommandeService {

    constructor(private http: HttpClient) { }

    add(commande: any) {
        return this.http.post<any>(environment.BASE_URL + `/api/commandes`, commande);
    }

    enCouture(id: number, commande: any) {
        return this.http.put<any>(environment.BASE_URL + `/api/commandes/${id}/en-couture`, commande);
    }

    edit(id: number, commande: any) {
        return this.http.put<any>(environment.BASE_URL + `/api/commandes/${id}`, commande);
    }

    livrerWithPayment(id: number, reliquat: any) {
        return this.http.put<any>(environment.BASE_URL + `/api/commandes/livrer-with-paiement/${id}`, reliquat);
    }

    changeStatut(body: any) {
        return this.http.post<any>(environment.BASE_URL + `/api/commandes/change-statut`, body);
    }

    enregistrerPaiement(id: number, reliquat: any) {
        return this.http.put<any>(environment.BASE_URL + `/api/commandes/paiement/${id}`, reliquat);
    }

    getAll(request) {
        const params = request;
        return this.http.get<Commande[]>(environment.BASE_URL + `/api/commandes`, {params});
    }

    findAll() {
        return this.http.get<Commande[]>(environment.BASE_URL + `/api/commandes/get/all`);
    }

    getAllReference() {
        return this.http.get<string[]>(environment.BASE_URL + `/api/commandes/references`);
    }

    getByStatut(statut, request) {
        const params = request;
        return this.http.get<Commande[]>(environment.BASE_URL + `/api/commandes/statut/${statut}`, {params});
    }

    getByClient(clientId, request) {
        const params = request;
        return this.http.get<Commande[]>(environment.BASE_URL + `/api/commandes/client/${clientId}`, {params});
    }

    statsByClient(clientId) {
        return this.http.get<StatsCommandeMois>(environment.BASE_URL + `/api/commandes/stats/client/${clientId}`);
    }

    findByStatut(statut) {
        return this.http.get<Commande[]>(environment.BASE_URL + `/api/commandes/bystatut/${statut}`);
    }

    getEnRetard(request) {
        const params = request;
        return this.http.get<Commande[]>(environment.BASE_URL + `/api/commandes/get/en-retard-former`, {params});
    }

    getEnCours() {
        return this.http.get<Commande[]>(environment.BASE_URL + `/api/commandes/get/en-cours`);
    }

    getLivraisonProche() {
        return this.http.get<Commande[]>(environment.BASE_URL + `/api/commandes/get/livraison-proche`);
    }

    terminer(id) {
        return this.http.get<Commande>(environment.BASE_URL + `/api/commandes/terminer/${id}`);
    }

    livrer(id) {
        return this.http.get<Commande>(environment.BASE_URL + `/api/commandes/livrer/${id}`);
    }

    getEnRetardList() {
        return this.http.get<Commande[]>(environment.BASE_URL + `/api/commandes/get/en-retard`);
    }

    getEnRetardDashboard(request) {
        const params = request;
        return this.http.get<Commande[]>(environment.BASE_URL + `/api/commandes/get/en-retard-dashboard`, {params});
    }


    getEnRetardATraiter() {
        return this.http.get<Commande[]>(environment.BASE_URL + `/api/commandes/get/retard-atraiter`);
    }

    getAllTerminees() {
        return this.http.get<Commande[]>(environment.BASE_URL + `/api/commandes/get/all-terminees`);
    }

    delete(id: number) {
        return this.http.delete<void>(environment.BASE_URL + `/api/commandes/${id}`);
    }

    getById(id: number) {
        return this.http.get<Commande>(environment.BASE_URL + `/api/commandes/${id}`);
    }

    findByRef(ref: string) {
        return this.http.get<Commande>(environment.BASE_URL + `/api/commandes/ref/${ref}`);
    }

    search(searchForm, request) {
        const params = request;
        return this.http.post<any>(environment.BASE_URL + `/api/commandes/search`, searchForm, {params});
    }

    searchByClient(searchForm, request) {
        const params = request;
        return this.http.post<any>(environment.BASE_URL + `/api/commandes/search/client`, searchForm, {params});
    }
    searchByCommande(searchForm, request) {
        const params = request;
        return this.http.post<any>(environment.BASE_URL + `/api/commandes/search/ref`, searchForm, {params});
    }

}