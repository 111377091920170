import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Commande } from 'src/app/core/models/commande.model';
import { CommandeService } from 'src/app/core/services/entities/commande.service';
import { LoaderService } from 'src/app/core/services/loader.service';

@Component({
  selector: 'app-change-priorite-modal',
  templateUrl: './change-priorite-modal.component.html',
  styleUrls: ['./change-priorite-modal.component.scss']
})
export class ChangePrioriteModalComponent implements OnInit {

  priorites = ['HAUTE', 'MOYENNE', 'BASSE'];

  filtrePriorite;
  currentPriorite;

  commande: Commande;


  submit = false;
  loading$ = this.loader.loading$;

  @Output()
  commandeChange: EventEmitter<Commande> = new EventEmitter<Commande>();

  constructor(public activeModal: NgbActiveModal, private service: CommandeService, private loader: LoaderService) { }

  ngOnInit(): void {
    this.currentPriorite = this.commande.priorite;
    this.filtrePriorite = this.currentPriorite;
  }


  changerPriorite() {

    this.submit = true;

    this.loader.show();

    let body = {
      id: this.commande.id,
      priorite: this.filtrePriorite
    };

    this.service.changePriorite(body).subscribe(
      data => {

        this.commandeChange.emit(data);

        this.loader.hide();

        this.activeModal.dismiss();

      },
      error => {

        console.log(error);
        this.loader.hide();
      }
    );


  }

}
