<app-loader *ngIf="loading$ | async"></app-loader>
<div class="modal-header">

    <h4 class="float-end font-size-16">Article {{position+1}} #{{refCommande}}</h4>
    <button type="button" class="btn-close" aria-hidden="true" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body back-gray">

    <div class="row container-fluid">
        <div class="col-lg-12 col-xl-12  col-sm-12">
            <div class="card">
                <div class="card-body">


                    <div class="row">


                        <div class="card">
                            <div class="card-body">

                                <div class="row" [formGroup]="enCoutureForm">

                                    <div class="col-lg-6">

                                        <div class="mb-3">
                                            <label for="nom" class="control-label">Modele</label>

                                            <input type="text" class="form-control" list="presetsModele"
                                                formControlName="modele" />
                                            <datalist id="presetsModele">
                                                <option *ngFor="let mod of modeles">{{mod.nom}}</option>
                                            </datalist>

                                        </div>

                                        <div class="mb-3">
                                            <label for="nom" class="control-label">Broderie</label>

                                            <input type="text" class="form-control" formControlName="broderie" />

                                        </div>

                                        <div *ngIf="statut=='EN_COUTURE'" class="mb-3">

                                            <label>Date de livraison prévue</label>
                                            <input type="date" class="form-control"
                                                formControlName="dateLivraisonPrevue"
                                                [ngClass]="{'is-invalid': submitStep && form.dateLivraisonPrevue.errors}">
                                            <div *ngIf="submitStep && form.dateLivraisonPrevue.errors"
                                                class="invalid-feedback">
                                                <span *ngIf="form.dateLivraisonPrevue.errors.required">Ce champs est
                                                    requis.</span>
                                            </div>

                                        </div>

                                        <div *ngIf="statut=='TERMINEE'" class="mb-3">

                                            <label>Date couture prête</label>
                                            <input type="date" class="form-control"
                                                formControlName="datePrete"
                                                [ngClass]="{'is-invalid': submitStep && form.datePrete.errors}">
                                            <div *ngIf="submitStep && form.datePrete.errors"
                                                class="invalid-feedback">
                                                <span *ngIf="form.datePrete.errors.required">Ce champs est
                                                    requis.</span>
                                            </div>

                                        </div>

                                        <div class="mb-3" *ngIf="statut=='LIVREE'">

                                            <label>Date de livraison effective</label>
                                            <input type="date" class="form-control"
                                                formControlName="dateLivraisonEffective"
                                                [ngClass]="{'is-invalid': submitStep && form.dateLivraisonEffective.errors}">
                                            <div *ngIf="submitStep && form.dateLivraisonEffective.errors"
                                                class="invalid-feedback">
                                                <span *ngIf="form.dateLivraisonEffective.errors.required">Ce champs est
                                                    requis.</span>
                                            </div>

                                        </div>

                                        <div class="mb-3">
                                            <label for="nom" class="control-label">Coupeurs</label>

                                            <ng-select formControlName="coupeurs" bindLabel="nom" [multiple]="true"
                                                bindValue="id" [items]="coupeurs"
                                                placeholder="Selectionner vos coupeurs">
                                            </ng-select>

                                        </div>

                                        <div class="mb-3">
                                            <label for="nom" class="control-label">Piqueurs</label>

                                            <ng-select formControlName="piqueurs" bindLabel="nom" [multiple]="true"
                                                bindValue="id" [items]="piqueurs"
                                                placeholder="Selectionner vos piqueurs">
                                            </ng-select>

                                        </div>

                                        <div class="mb-3">
                                            <label for="nom" class="control-label">Brodeurs</label>

                                            <ng-select formControlName="brodeurs" bindLabel="nom" [multiple]="true"
                                                bindValue="id" [items]="brodeurs"
                                                placeholder="Selectionner vos brodeurs">
                                            </ng-select>

                                        </div>

                                    </div>

                                    <div class="col-lg-6">


                                        <div formArrayName="etapes">
                                            <h5 class="font-size-14"> Etapes complétées</h5>
                                            <p class="sub-header mb-4">Cochez les étapes complétées</p>

                                            <div>
                                                <div *ngFor="let etape of couture?.etapes;  let j=index;"
                                                    [formGroupName]="j" class="form-check form-check-primary mb-3">
                                                    <input formControlName="completed" class="form-check-input"
                                                        type="checkbox" [checked]="etape.completed">
                                                    <label class="form-check-label">
                                                        {{etape.etapeCouture | titlecase}}
                                                    </label>
                                                </div>

                                            </div>
                                        </div>



                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>



                </div>
            </div>
        </div>

    </div>
    <!-- end row -->



</div>
<div class="modal-footer">

    <button class="btn btn-primary" (click)="save()">Enregistrer</button>

    <button class="btn btn-secondary" (click)="activeModal.close('Close click')">Fermer</button>
</div>