<app-loader *ngIf="loading$ | async"></app-loader>
<div class="modal-header">
    <h5 *ngIf="statut=='TERMINEE'"  class="modal-title mt-0">Terminer la commande #{{commande.reference}}</h5>
    <h5 *ngIf="statut=='LIVREE'"  class="modal-title mt-0">Livrer la commande #{{commande.reference}}</h5>
    
    <button type="button" class="btn-close" aria-hidden="true" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">



    <form [formGroup]="terminerForm">
        <div class="row">
            <div class="col-lg-12">
                <label  *ngIf="statut=='TERMINEE'">Indiquez la date à la quelle la commande a été commande terminée</label>
                <label  *ngIf="statut=='LIVREE'">Indiquez la date à la quelle la commande a été commande livrée</label>


                <div 
                    class="row mb-4 mt-4">
                    <div class="col-lg-12">
                        <label *ngIf="statut=='TERMINEE'">Date commande prete</label>
                        <label *ngIf="statut=='LIVREE'">Date livraison effective</label>
                        <input type="date" [max]="today" class="form-control" formControlName="date"
                            [ngClass]="{'is-invalid': submit && form.date.errors}">
                        <div *ngIf="submit && form.date.errors" class="invalid-feedback">
                            <span *ngIf="form.date.errors.required">Ce champs est requis.</span>
                            <span *ngIf="form.date.errors.max">Cette date ne peut pas depasser la date du
                                jour</span>
                        </div>
                    </div>
                </div>

            </div>
        </div>


    </form>

</div>
<div class="modal-footer">
   
    <button *ngIf="statut=='TERMINEE'" type="button" (click)="changeStatutCommande()" class="btn btn-success">Terminer la commande</button>
    <button *ngIf="statut=='LIVREE'" type="button" (click)="changeStatutCommande()" class="btn btn-success">Livrer la commande</button>
    <button class="btn btn-secondary" (click)="activeModal.close('Close click')">Fermer</button>
</div>
