<div class="row mb-2" *ngIf="false">
  <div class="col-sm-4">
    <div class="search-box me-2 mb-2 d-inline-block">
      <div class="position-relative">
        <input type="text" class="form-control" placeholder="Recherche..." [(ngModel)]="term">
        <i class="bx bx-search-alt search-icon"></i>
      </div>
    </div>
  </div>
  <div class="col-sm-8">
    
  </div><!-- end col-->
</div>
<div class="table-responsive mb-0" *ngIf="transactions && transactions.length > 0" >
  <table class="table table-centered table-nowrap">
    <thead class="table-light">
      <tr>
        <th class="align-middle">Ref</th>
        <th class="align-middle">Articles</th>
        <th class="align-middle">Client</th>
        <th class="align-middle">Date commande</th>
        <th class="align-middle">Date livraison</th>
        <th class="align-middle">Priorité</th>
        <th class="align-middle">Statut</th>
       
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of transactions  | filter:term">
        
        
        <td>
          <a (click)="openDetailCommandeModal(data)" href="javascript: void(0);" >{{data.reference}}</a>
        </td>
        <td><span *ngFor="let article  of data.coutures; let i= index">{{article.typeVetement}} <br/> </span></td>
        <td>{{data?.client?.nom}}
          <p *ngIf="isAdmin || isBoutique" class="text-muted mb-0">{{data?.client?.telephone}}</p>
        </td>
        <td>{{data.dateCommande | date:'d MMM y' }}</td>
        <td>{{(data.dateLivraisonEffective? data.dateLivraisonEffective :  data.dateLivraison) | date:'d MMM y' }}</td>

        <td>
          
          <div *ngIf="data.priorite">
            <span class="badge badge-pill badge-soft-danger font-size-11" [ngClass]=" { 'badge-soft-warning': data.priorite === 'MOYENNE',
            'badge-soft-danger': data.priorite === 'HAUTE','badge-soft-info':data.priorite === 'BASSE' }">
            {{data.priorite | titlecase}}
            </span>
          </div>
        </td>

        <td>
          <span class="badge badge-pill badge-soft-success font-size-11" [ngClass]=" { 'badge-soft-warning': data.statut === 'A_TRAITER',
          'badge-soft-primary': data.statut === 'EN_COUTURE','badge-soft-success':data.statut === 'LIVREE', 
          'badge-soft-danger': data.statut === 'RETOUR','badge-soft-info':data.statut === 'TERMINEE' }">
              {{ transform(data.statut) }} 
          </span>
          
          <br/>
         
          <span class="badge badge-pill badge-soft-success font-size-11" [ngClass]=" { 'badge-soft-warning': data.statutPaiement === 'A_PAYER','badge-soft-success':data.statutPaiement === 'PAYEE', 
          'badge-soft-info':data.statutPaiement === 'ACOMPTE' }">
          {{ transform(data.statutPaiement) }} 
          </span>

        </td>

      </tr>
    </tbody>
  </table>

</div>
<!-- end table -->
<div *ngIf="!transactions || transactions.length == 0">
  Aucune commande

</div>