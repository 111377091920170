<app-loader *ngIf="loading$ | async"></app-loader>
<div class="modal-header">
    <h5 *ngIf="selectedCommande.statut!='LIVREE'" class="modal-title mt-0">Livraison commande {{selectedCommande.reference}}</h5>
    <button type="button" class="btn-close" aria-hidden="true" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">



    <div class="mb-3">
        <h5 class="font-size-14"> Statut paiement:
            <span class="badge badge-pill badge-soft-success font-size-11"
                [ngClass]=" { 'badge-soft-warning': selectedCommande.statutPaiement === 'A_PAYER',
              'badge-soft-success':selectedCommande.statutPaiement === 'PAYEE','badge-soft-info':selectedCommande.statutPaiement === 'ACOMPTE' }">
                {{selectedCommande.statutPaiement | statut}}
            </span>
        </h5>

    </div>

    <div class="mb-3">
        <h5 class="font-size-14"> Cout de la commande:

        </h5>
        <p>
            {{selectedCommande.cout | currency : monnaie : 'symbol': '1.0-0': 'fr'}}
        </p>

    </div>




    <div class="mb-3" *ngIf="selectedCommande.paiements.length > 0">



        <h5 class="font-size-14"> Paiements:
        </h5>

        <p *ngFor="let pay of selectedCommande.paiements">{{pay.montant | currency : monnaie : 'symbol': '1.0-0': 'fr'}}
            {{pay.moyenPaiement | statut}} le
            {{pay.date | date:'d MMM y'}} </p>


    </div>

    <div class="mb-3">
        <h5 class="font-size-14"> Reste à payer: </h5>
        <p>
            {{getReliquat() | currency : monnaie : 'symbol': '1.0-0': 'fr'}}
        </p>

    </div>

    <form [formGroup]="reliquatForm">
        <div class="row">
            <div class="col-lg-12">
                <label>Paiement reliquat</label>

                <div>
                    <div class="form-check form-check-inline font-size-16">
                        <input formControlName="paiementReliquat" value="PAYEE" class="form-check-input" type="radio"
                            name="paiementReliquat" id="paiementMaintenant"
                            [ngClass]="{'is-invalid': submit && form.paiementReliquat.errors}">
                        <label class="form-check-label font-size-13" for="paiementMaintenant">Paiement integral</label>
                    </div>
                    <div class="form-check form-check-inline font-size-16">
                        <input value="ACOMPTE" formControlName="paiementReliquat" class="form-check-input" type="radio"
                            name="paiementReliquat" id="paiementAvance"
                            [ngClass]="{'is-invalid': submit && form.paiementReliquat.errors}">
                        <label class="form-check-label font-size-13" for="paiementAvance">Acompte sur reliquat</label>
                    </div>
                    <div *ngIf="selectedCommande.statut!='LIVREE'" class="form-check form-check-inline font-size-16">
                        <input value="A_PAYER" formControlName="paiementReliquat" class="form-check-input" type="radio"
                            name="paiementReliquat" id="paiementLivraison"
                            [ngClass]="{'is-invalid': submit && form.paiementReliquat.errors}">
                        <label class="form-check-label font-size-13" for="paiementLivraison">Paiement plus tard</label>
                    </div>

                    <div *ngIf="form.paiementReliquat.errors" class="invalid-feedback">
                        <span *ngIf="form.paiementReliquat.errors.required">Ce champs est requis.</span>
                    </div>
                </div>

                <div *ngIf="form.paiementReliquat.value == 'ACOMPTE'" class="row mb-4">
                    <div class="col-lg-4">
                        <div class="mt-4 mb-0">
                            <label>Montant paiement</label>
                            <input type="number" [max]="getReliquat()" class="form-control" formControlName="montantPaiement"
                                [ngClass]="{'is-invalid': submit && form.montantPaiement.errors}">
                            <div *ngIf="submit && form.montantPaiement.errors" class="invalid-feedback">
                                <span *ngIf="form.montantPaiement.errors.max">L'avance ne peut pas depasser le cout
                                    total</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="form.paiementReliquat.value == 'PAYEE' || form.paiementReliquat.value == 'ACOMPTE' "
                    class="row mb-4 mt-4">
                    <div class="col-lg-4">
                        <label>Date paiement</label>
                        <input type="date" [max]="today" class="form-control" formControlName="datePaiement"
                            [ngClass]="{'is-invalid': submit && form.datePaiement.errors}">
                        <div *ngIf="submit && form.datePaiement.errors" class="invalid-feedback">
                            <span *ngIf="form.datePaiement.errors.required">Ce champs est requis.</span>
                            <span *ngIf="form.datePaiement.errors.max">Cette date ne peut pas depasser la date du
                                jour</span>
                        </div>
                    </div>
                </div>

                <div *ngIf="form.paiementReliquat.value == 'PAYEE' || form.paiementReliquat.value == 'ACOMPTE' "
                    class="row mt-4">
                    <div class="col-lg-12">
                        <label>Moyen de paiement</label>

                        <div>
                            <div class="form-check form-check-inline font-size-16">
                                <input formControlName="moyenPaiement" value="ESPECES" class="form-check-input"
                                    type="radio" name="moyenPaiement" id="especes">
                                <label class="form-check-label font-size-13" for="especes">Espèces</label>
                            </div>
                            <div class="form-check form-check-inline font-size-16">
                                <input value="WAVE" formControlName="moyenPaiement" class="form-check-input"
                                    type="radio" name="moyenPaiement" id="wave">
                                <label class="form-check-label font-size-13" for="wave">Wave</label>
                            </div>
                            <div class="form-check form-check-inline font-size-16">
                                <input value="ORANGE_MONEY" formControlName="moyenPaiement" class="form-check-input"
                                    type="radio" name="moyenPaiement" id="orangemoney">
                                <label class="form-check-label font-size-13" for="orangemoney">Orange Money</label>
                            </div>
                            <div class="form-check form-check-inline font-size-16">
                                <input value="CARTE_BANCAIRE" formControlName="moyenPaiement" class="form-check-input"
                                    type="radio" name="moyenPaiement" id="cartebancaire">
                                <label class="form-check-label font-size-13" for="cartebancaire">Carte bancaire</label>
                            </div>
                            <div class="form-check form-check-inline font-size-16">
                                <input value="CHEQUE" formControlName="moyenPaiement" class="form-check-input"
                                    type="radio" name="moyenPaiement" id="cheque">
                                <label class="form-check-label font-size-13" for="cheque">Chèque</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mb-4 mt-4">
                    <div class="col-lg-6">
                        <label>Date livraison effective</label>
                        <input type="date" [max]="today" class="form-control" formControlName="dateLivraisonEffective"
                            [ngClass]="{'is-invalid': submit && form.dateLivraisonEffective.errors}">
                        <div *ngIf="submit && form.dateLivraisonEffective.errors" class="invalid-feedback">
                            <span *ngIf="form.dateLivraisonEffective.errors.required">Ce champs est requis.</span>
                            <span *ngIf="form.dateLivraisonEffective.errors.max">Cette date ne peut pas depasser la date du
                                jour</span>
                        </div>
                    </div>
                </div>

            </div>
        </div>


    </form>

</div>
<div class="modal-footer">
    <button *ngIf="selectedCommande.statut!='LIVREE'" type="button"
        (click)="livraisonCommandeWithPayment()" class="btn btn-success">Livrer commande</button>
    <button *ngIf="selectedCommande.statut=='LIVREE'" type="button"
        (click)="livraisonCommandeWithPayment()" class="btn btn-success">Enregister paiement</button>
    <button class="btn btn-secondary" (click)="activeModal.close('Close click')">Fermer</button>
</div>