<div class="card">
    <div class="card-body">
        <h4 class="card-title mb-4" *ngIf="statsCommandeMois.total > 1">{{statsCommandeMois.total}} commandes {{text}}</h4>
        <h4 class="card-title mb-4" *ngIf="statsCommandeMois.total < 2">{{statsCommandeMois.total}} commande {{text}}</h4>

        <div>
            <apx-chart class="apex-charts" dir="ltr" [series]="salesAnalyticsDonutChart.series"
                [chart]="salesAnalyticsDonutChart.chart" [colors]="salesAnalyticsDonutChart.colors"
                [plotOptions]="salesAnalyticsDonutChart.plotOptions"
                [legend]="salesAnalyticsDonutChart.legend" [labels]="salesAnalyticsDonutChart.labels">
            </apx-chart>
        </div>

        <div class="text-center text-muted">
            <div class="row">
                <div class="col-3">
                    <div class="mt-4">
                        <p class="mb-2 text-truncate"><i class="mdi mdi-circle text-warning me-1"></i>
                            A traiter</p>
                        <h5>{{statsCommandeMois.atraiter}}</h5>
                    </div>
                </div>
                <div class="col-3">
                    <div class="mt-4">
                        <p class="mb-2 text-truncate"><i class="mdi mdi-circle text-primary me-1"></i>
                            En couture</p>
                        <h5>{{statsCommandeMois.enCouture}}</h5>
                    </div>
                </div>


                <div class="col-3">
                    <div class="mt-4">
                        <p class="mb-2 text-truncate"><i class="mdi mdi-circle text-info me-1"></i>
                            Terminée</p>
                        <h5>{{statsCommandeMois.terminees}}</h5>
                    </div>
                </div>
                <div class="col-3">
                    <div class="mt-4">
                        <p class="mb-2 text-truncate"><i class="mdi mdi-circle text-success me-1"></i>
                            Livrée</p>
                        <h5>{{statsCommandeMois.livrees}}</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>