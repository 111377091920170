<div class="modal-header">

  <h4 class="float-end font-size-16">Detail commande # {{commande.reference}}</h4>
  <button type="button" class="btn-close" aria-hidden="true" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body back-gray">

  <div class="row container-fluid">
    <div class="col-lg-12 col-xl-9  col-sm-12">
      <div class="card">
        <div class="card-body">

          <div class="row">
            <div class="col-6">
              <address>

                <h5 class="font-size-14"><i class="bx bx-user me-1 text-primary"></i> Client</h5>
                {{commande.client.nom}}<br>
                {{!(isAdmin || isBoutique)? '' : commande.client.telephone}}<br
                  *ngIf="(isAdmin || isBoutique)  && commande.client.telephone">
                {{commande.client.adresse}}<br *ngIf="commande.client.adresse">
                {{commande.client.email}}<br *ngIf="commande.client.email">

              </address>
            </div>

            <div class="col-6 text-sm-end">
              <address>
                <h5 class="font-size-14"><i class="bx bx bx-loader-circle me-1 text-primary"></i> Statut de la commande
                </h5>
                <p class="text-muted mb-0">
                <p class="text-muted mb-0">
                  <span class="badge badge-pill badge-soft-success font-size-11"
                    [ngClass]=" { 'badge-soft-warning': commande.statut === 'A_TRAITER',
                    'badge-soft-primary': commande.statut === 'EN_COUTURE','badge-soft-success':commande.statut === 'LIVREE', 
                    'badge-soft-danger': commande.statut === 'RETOUR','badge-soft-info':commande.statut === 'TERMINEE' }">
                    {{commande.statut | statut }}
                  </span>

                </p>

              </address>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <address>
                <h5 class="font-size-14"><i class="bx bx-money me-1 text-primary"></i> Statut paiement
                </h5>
                <p class="text-muted mb-0">


                  <span class="badge badge-pill badge-soft-success font-size-11"
                    [ngClass]=" { 'badge-soft-warning': commande.statutPaiement === 'A_PAYER',
                  'badge-soft-success':commande.statutPaiement === 'PAYEE','badge-soft-info':commande.statutPaiement === 'ACOMPTE' }">
                    {{commande.statutPaiement | statut }}
                  </span>
                </p>
              </address>
            </div>

            <div class="col-6 text-sm-end">
              <address>
                <h5 class="font-size-14"><i class="bx bx-calendar-check me-1 text-primary"></i> Date de la commande
                </h5>
                <p class="text-muted mb-0"> {{commande.dateCommande | date:'d MMM y'}}</p>

              </address>
            </div>
          </div>
          <div class="row">
            <div class="col-6 mt-3">
              <address *ngIf="(isAdmin || isBoutique)  && commande.paiements.length > 0">

                <h5 class="font-size-14"><i class="bx bx-money me-1 text-primary"></i> Paiements
                </h5>

                <p *ngFor="let pay of commande.paiements">{{pay.montant | currency : monnaie : 'symbol': '1.0-0': 'fr'}}
                  {{pay.moyenPaiement | statut }} le
                  {{pay.date | date:'d MMM y'}} </p>

              </address>
            </div>
            <div class="col-6 text-sm-end">
              <address>
                <h5 class="font-size-14"><i class="bx bx-calendar-check me-1 text-primary"></i> Date livraison prévue
                </h5>
                <p class="text-muted mb-0"> {{commande.dateLivraison | date:'d MMM y'}}</p>

              </address>
            </div>

          </div>

          <div class="row" *ngIf="commande.dateLivraisonEffective">
            <div class="col-6 mt-3">
              
            </div>
            <div class="col-6 text-sm-end">
              <address>
                <h5 class="font-size-14"><i class="bx bx-calendar-check me-1 text-primary"></i> Date livraison effective
                </h5>
                <p class="text-muted mb-0"> {{commande.dateLivraisonEffective | date:'d MMM y'}}</p>

              </address>
            </div>
            
          </div>
          
          <div class="row">
            <div *ngIf="commande.notes" class="col-12 mt-3">
              <address>
                <strong>Notes:</strong><br>
                {{commande.notes}}<br><br>
              </address>
            </div>
          </div>
          <div class="py-2 mt-3">

            <h5 class="font-size-18">Liste des articles</h5>
          </div>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>

                  <th>Tissu</th>
                  <th>Nom</th>
                  <th>Designation</th>
                  <th>Mesures</th>
                  <th>Notes</th>
                  <th *ngIf="commande.statut !='A_TRAITER'">Affectations</th>
                  <th *ngIf="commande.statut !='A_TRAITER'">Etapes</th>
                  <th *ngIf="isAdmin || isBoutique" class="text-end">Cout</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let article of commande.coutures; let i=index">

                  <td>
                    <div class="card" *ngIf="article.photoTissu">
                      <img (click)="openImage(article.photoTissu)" class="avatar-md" src="{{ article.photoTissu}}" alt="Photo Tissu couture">
                      <div class="py-2 text-center">
                        <a href="javascript: void(0);" (click)="openImage(article.photoTissu)" class="fw-medium" >Agrandir</a>
                      </div>
                    </div>
                    <img *ngIf="false" src="assets/images/no-image.jpg" alt="product-img" title="product-img" class="avatar-md" />
                    <div *ngIf="!article.photoTissu && article.couleur" class="box" [style.background-color]="article.couleur"> <span class="align-middle">{{article.typeTissu}}</span> </div>
                    <div *ngIf="!article.photoTissu && !article.couleur && article.typeTissu">{{article.typeTissu}}</div>
                  </td>

                  <td>
                    {{article.nomProprietaire}}
                  </td>

                  <td>
                    {{article.typeVetement}}
                    <p *ngIf="article.modele" class="mb-0">Modele : <span class="fw-medium">{{article.modele}}</span></p>
                    <p *ngIf="article.broderie" class="mb-0">Broderie : <span class="fw-medium">{{article.broderie}}</span></p>
                    
                  </td>

                  <td>
                    <span *ngFor="let mesure of article.mesuresList; let i=index"> <i *ngIf="mesure.valeur && i!=0">,
                      </i> <span *ngIf="mesure.valeur">{{mesure.champ}} : {{mesure.valeur}}</span> </span>

                  </td>
                  <td>{{article.notes}}</td>
                  <td *ngIf="commande.statut !='A_TRAITER'">

                    <p *ngIf="article.coupeurs && article.coupeurs.length > 0">
                      Coupeurs :<br /> <span *ngFor="let perso of article.coupeurs"
                        class="badge bg-light font-size-11 mr-2">{{perso.nom}}</span>
                    </p>

                    <p *ngIf="article.piqueurs && article.piqueurs.length > 0">
                      Piqueurs :<br /><span *ngFor="let perso of article.piqueurs"
                        class="badge bg-light font-size-11">{{perso.nom}}</span>
                    </p>

                    <p *ngIf="article.brodeurs && article.brodeurs.length > 0">
                      Brodeurs :<br /><span *ngFor="let perso of article.brodeurs"
                        class="badge bg-light font-size-11">{{perso.nom}}</span>
                    </p>
                  </td>

                  <td *ngIf="commande.statut !='A_TRAITER'">

                    <div *ngFor="let etape of article.etapes">
                      <p *ngIf="etape.completed" class="form-check form-check-sucess">                      
                        <input class="form-check-input" type="checkbox" checked disabled/> {{etape.etapeCouture | titlecase}} 
                      </p>
                    </div> 

                  </td>
                  <td *ngIf="isAdmin || isBoutique" class="text-end">{{article.cout | currency : monnaie : 'symbol': '1.0-0':
                    'fr'}}</td>
                </tr>


                <tr *ngIf="isAdmin || isBoutique">
                  <td [attr.colspan]="commande.statut !='A_TRAITER'? 7 : 5" class="border-0 text-end">
                    <strong>Total</strong>
                  </td>
                  <td class="border-0 text-end">
                    <h4 class="m-0">{{ commande.cout | currency : monnaie : 'symbol': '1.0-0': 'fr'}}</h4>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>
      </div>
    </div>
    <div class="col-xl-3 col-lg-12 col-sm-12">


      <div class="card">
        <div class="card-body">
          <h4 class="card-title mb-5">Activités</h4>
          <ul class="verti-timeline list-unstyled" *ngIf="commande.activites">
            <li *ngFor="let activity of commande.activites" class="event-list">
              <div class="event-timeline-dot">
                <i class="bx bx-right-arrow-circle font-size-18"></i>
              </div>
              <div class="media">
                <div class="mr-3">
                  <h5 class="font-size-10">{{activity.date | date :'dd MMM, YYYY HH:mm' }} <i
                      class="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2"></i>
                  </h5>
                </div>
                <div class="media-body">
                  <div>
                    {{activity.description}}
                    <p>
                      <small class="text-muted">{{activity.acteur}}</small>
                    </p>
                  </div>
                </div>
              </div>
            </li>

          </ul>
          <div class="text-center mt-4"></div>
        </div>
      </div>



    </div>
  </div>
  <!-- end row -->



</div>
<div class="modal-footer">

  <button *ngIf="isAdmin || isBoutique" class="btn btn-outline-secondary" routerLink="/commandes/facture/{{commande.id}}">Voir
    facture</button>
  <button *ngIf="commande.statut=='A_TRAITER' || commande.statut=='EN_COUTURE' "
    routerLink="/commandes/en-couture/{{commande.id}}" class="btn btn-primary">En couture</button>
  <button *ngIf="commande.statut=='A_TRAITER' || commande.statut=='EN_COUTURE' "
    (click)="confirmTerminee(commande.id, commande.reference)" class="btn btn-success">Terminer</button>
  <button *ngIf="commande.statut=='TERMINEE'  && commande.statutPaiement == 'PAYEE'"
    (click)="confirmLivree(commande.id, commande.reference)" class="btn btn-success">Livrer</button>
  <button *ngIf="commande.statut=='TERMINEE' && commande.statutPaiement != 'PAYEE'" (click)="openLivraisonModal()"
    class="btn btn-success">Livrer</button>
  <button *ngIf="commande.statutPaiement != 'PAYEE'" (click)="openPaiementModal()"
    class="btn btn-outline-success">Enregistrer un paiement</button>
  <button class="btn btn-outline-primary"
    *ngIf="(isBoutique && (commande.statut=='EN_COUTURE' || commande.statut=='A_TRAITER')) || isAdmin"
    routerLink="/commandes/modification/{{commande.id}}">Modifier</button>
  <button class="btn btn-outline-danger" (click)="confirmDelete(commande.id)"
    *ngIf="isAdmin">Supprimer</button>
  <button class="btn btn-secondary" (click)="activeModal.close('Close click')">Fermer</button>
</div>