import { Component, Input, OnInit } from '@angular/core';
import { ChartType } from 'src/app/core/models/chart.model';
import { ParametrageService } from 'src/app/core/services/entities/parametrage.service';

@Component({
  selector: 'app-stats-paiements-recus',
  templateUrl: './stats-paiements-recus.component.html',
  styleUrls: ['./stats-paiements-recus.component.scss']
})
export class StatsPaiementsRecusComponent implements OnInit {

  @Input() montant: any;

  monnaie = 'XOF';

  @Input() text: string;


  salesAnalyticsDonutChart: ChartType;

  constructor(private parametrageService: ParametrageService) { }

  ngOnInit(): void {
    let data = this.montant;

    this.monnaie = this.parametrageService.getCurrency();

    this.salesAnalyticsDonutChart = {
      series: [data.recu, data.total - data.recu],
      chart: {
          type: 'pie',
          height: 240,
      },
      labels: ['Paiements reçus', 'Reste à payer'],
      colors: ['#34c38f', '#f1b44c'],
      legend: {
          show: false,
      },
      plotOptions: {
          pie: {
              donut: {
                  size: '70%',
              }
          }
      }
    }
  }

}
