import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from "src/environments/environment";
import { Personnel } from '../../models/personnel.model';

@Injectable({ providedIn: 'root' })
export class PersonnelService {

    constructor(private http: HttpClient) { }

    getAll(request) {
        const params = request;
        return this.http.get<Personnel[]>(environment.BASE_URL + `/api/personnel`, {params});
    }

    list() {
        return this.http.get<Personnel[]>(environment.BASE_URL + `/api/personnel/get/all`);
    }

    add(personnel: any) {
        return this.http.post<Personnel>(environment.BASE_URL + `/api/personnel`, personnel);
    }

    edit(idPersonnel: number, personnel: any) {
        return this.http.put<Personnel>(environment.BASE_URL + `/api/personnel/${idPersonnel}`, personnel);
    }

    delete(idPersonnel: number) {
        return this.http.delete<void>(environment.BASE_URL + `/api/personnel/${idPersonnel}`);
    }

    getById(idPersonnel: number) {
        return this.http.get<Personnel>(environment.BASE_URL + `/api/personnel/${idPersonnel}`);
    }

  
}
