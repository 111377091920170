<div class="card">
    <div class="card-body">
         <h4 class="card-title mb-4" *ngIf="montant"> Total des commandes {{text}}: <span class="text-primary">{{montant.total | currency : monnaie : 'symbol': '1.0-0': 'fr' }} </span> </h4>


        <div>
            <apx-chart class="apex-charts" dir="ltr" [series]="salesAnalyticsDonutChart.series"
                [chart]="salesAnalyticsDonutChart.chart" [colors]="salesAnalyticsDonutChart.colors"
                [plotOptions]="salesAnalyticsDonutChart.plotOptions"
                [legend]="salesAnalyticsDonutChart.legend" [labels]="salesAnalyticsDonutChart.labels">
            </apx-chart>
        </div>

        <div class="text-center text-muted">
            <div class="row">
                <div class="col-6">
                    <div class="mt-4">
                        <p class="mb-2 text-truncate"><i class="mdi mdi-circle text-success me-1"></i>
                         Total des paiements reçus</p>
                        <h5>{{montant.recu | currency : monnaie : 'symbol': '1.0-0': 'fr' }}</h5>
                    </div>
                </div>
                <div class="col-6">
                    <div class="mt-4">
                        <p class="mb-2 text-truncate"><i class="mdi mdi-circle text-warning me-1"></i>
                            Reste à payer</p>
                        <h5>{{(montant.total - montant.recu) | currency : monnaie : 'symbol': '1.0-0': 'fr' }}</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>