import { Component, Input, OnInit } from '@angular/core';
import { ChartType } from 'src/app/core/models/chart.model';
import { StatsCommandeMois } from 'src/app/core/models/stats-commande-mois.model';

@Component({
  selector: 'app-stats-commande-statut',
  templateUrl: './stats-commande-statut.component.html',
  styleUrls: ['./stats-commande-statut.component.scss']
})
export class StatsCommandeStatutComponent implements OnInit {

  @Input() statsCommandeMois: StatsCommandeMois;

  @Input() text: string;
  salesAnalyticsDonutChart: ChartType;

  constructor() { }

  ngOnInit(): void {

    let data = this.statsCommandeMois;

    this.salesAnalyticsDonutChart = {
      series: [data.atraiter, data.enCouture, data.terminees, data.livrees],
      chart: {
          type: 'donut',
          height: 240,
      },
      labels: ['A traiter', 'En couture', 'Terminée', 'Livrée'],
      colors: ['#f1b44c', '#556ee6', '#50a5f1', '#34c38f'],
      legend: {
          show: false,
      },
      plotOptions: {
          pie: {
              donut: {
                  size: '70%',
              }
          }
      }
    }
  }

}
