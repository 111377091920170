<app-loader *ngIf="loading$ | async"></app-loader>
<div class="modal-header">
    <h5 class="modal-title mt-0">Changer priorité commande #{{commande.reference}}</h5>

    <button type="button" class="btn-close" aria-hidden="true" (click)="activeModal.dismiss('Cross click')"></button>
</div>

<div class="modal-body">



    <div class="row mb-4 mt-4">
        <div class="col-lg-8">
            <label>Priorité de la commande</label>

            <ng-select [items]="priorites" [clearable]="true" name="selectPriorite"
                [ngModelOptions]="{standalone: true}" [(ngModel)]="filtrePriorite"></ng-select>
        </div>
    </div>



</div>

<div class="modal-footer">

    <button [disabled]="filtrePriorite === currentPriorite" type="button" (click)="changerPriorite()"
        class="btn btn-success">Changer la priorité</button>
    <button class="btn btn-secondary" (click)="activeModal.close('Close click')">Fermer</button>
</div>