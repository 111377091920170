import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { Commande } from 'src/app/core/models/commande.model';

import { CommandeService } from 'src/app/core/services/entities/commande.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import Swal from 'sweetalert2';
import { PaiementCommandeModalComponent } from '../paiement-commande-modal/paiement-commande-modal.component';
import { ParametrageService } from 'src/app/core/services/entities/parametrage.service';
import { LivraisonWithPaiementComponent } from '../livraison-with-paiement/livraison-with-paiement.component';
import { Lightbox } from 'ngx-lightbox';
import { DetailCoutureComponent } from 'src/app/pages/couture/modal/detail-couture/detail-couture.component';
import { ModeleService } from 'src/app/core/services/entities/modele.service';
import { TerminerCommandeModalComponent } from '../terminer-commande-modal/terminer-commande-modal.component';
import { ImageService } from 'src/app/core/services/image.service';
import { ChangePrioriteModalComponent } from '../change-priorite-modal/change-priorite-modal.component';

@Component({
  selector: 'app-detail-commande-modal',
  templateUrl: './detail-commande-modal.component.html',
  styleUrls: ['./detail-commande-modal.component.scss']
})
export class DetailCommandeModalComponent implements OnInit {

  selectedCommande: Commande;

  @Input() commande: Commande;

  @Output()
  statusChange : EventEmitter<string> = new EventEmitter<string>();

  @Output()
  priorityChange : EventEmitter<string> = new EventEmitter<string>();

  @Output()
  retirerEvent : EventEmitter<number> = new EventEmitter<number>();

  @Output()
  deleteEvent : EventEmitter<number> = new EventEmitter<number>();

  @Output()
  commandeChange : EventEmitter<Commande> = new EventEmitter<Commande>();

  isRoleAtelier;
  isAdmin;
  isBoutique;
  submit = false;

  monnaie;
  tenant;

  modeles;


  constructor(public activeModal: NgbActiveModal, private modalService: NgbModal, private loader: LoaderService
    , private service: CommandeService, private parametrageService: ParametrageService
    , private imageService: ImageService, private lightbox: Lightbox, private modeleService: ModeleService) { }

  ngOnInit(): void {

    let role = sessionStorage.getItem('role');
    this.isRoleAtelier = role == 'Atelier';
    this.tenant = sessionStorage.getItem('tenant');
    this.isAdmin = role == 'Admin Couturier';
    this.isBoutique = role == 'Boutique';
    this.monnaie = this.parametrageService.getCurrency();
    this.getModeles();
  }

  fetchCommande(){
    this.loader.show();
    this.service.getById(this.commande.id).subscribe(commande=>{
      
      this.commande = commande;
      this.loader.hide();
    },
    error => {
      this.loader.hide();
    });
  }

  viewCouture(couture, ref, position){
    const modalRef = this.modalService.open(DetailCoutureComponent, { size: 'xl', centered: true });
		modalRef.componentInstance.couture = couture;
    modalRef.componentInstance.refCommande = ref;
    modalRef.componentInstance.monnaie = this.monnaie;
    modalRef.componentInstance.modeles = this.modeles;
    modalRef.componentInstance.dateLivraison = this.commande.dateLivraison;
    modalRef.componentInstance.position = position;
    modalRef.componentInstance.idCommande = this.commande.id;
    modalRef.componentInstance.coutureChange.subscribe(c => {
      couture = c;
      this.commande.coutures[position] = c;
      this.fetchCommande()
    });

  }

  getModeles() {

		this.modeleService.getAll().subscribe(
        data => {
          this.modeles = data;
        }, 
        error => {
		    }
		);
	}

  confirmDelete(id: number) {
    Swal.fire({
      title: 'Etes vous sûre?',
      text: 'Vous êtes sur le point de supprimer cet commande',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Oui, supprimer!'
    }).then(result => {
      if (result.value) {
        this.delete(id);
        
      }
    });
  }

  delete(id: number){
    this.loader.show();
    let photos = this.getImagesFromCommande(this.commande);
      this.service.delete(id).subscribe(
        () => {
            this.retirerEvent.emit(id);
            this.deleteEvent.emit(id);
            this.loader.hide();
            this.activeModal.dismiss();
            Swal.fire('Supprimé!', 'La commande a été supprimée.', 'success');
            photos.forEach(image => {
              this.imageService.deleteFileOnStorage(image);
            });
            

        },
        error => {
          this.loader.hide();
        });
  }

  openImage(url: string): void {
    // open lightbox

    const images = [];

    const src = url;
      const caption = 'Image Tissu';
      const thumb = url;
      const album = {
        src,
        caption,
        thumb
      };

      images.push(album);
    
    this.lightbox.open(images, 0, {
      showZoom: true
    });
  }

  getImagesFromCommande(commandeParam) :  string[]{

    let result = [];

    if(!commandeParam) return result;

    for(var couture  of commandeParam?.coutures){
      if(couture.photoTissu) result.push(couture.photoTissu);
    }

    return result;
  }

  confirmTerminee(id: number, ref: string) {
    Swal.fire({
      title: 'Etes vous sûre?',
      text: 'Vous êtes sur le point de déclarer la commande ' + ref + ' comme terminée',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Oui, terminer!',
      cancelButtonText: 'Annuler'
    }).then(result => {
      if (result.value) {
        this.terminer(id);
        
      }
    });
  }



  openChangeStatut(id: number, statut) {

    const modalRef = this.modalService.open(TerminerCommandeModalComponent, { size: 'md', centered: true });
		modalRef.componentInstance.commande = this.commande;
    modalRef.componentInstance.statut = statut;
    modalRef.componentInstance.statusChange.subscribe(val => this.statusChange.emit(val));
    modalRef.componentInstance.retirerEvent.subscribe(id => this.retirerEvent.emit(id));
    modalRef.componentInstance.commandeChange.subscribe(c => {
      this.commande = c;
      this.commandeChange.emit(c);
    });
    
  }

  terminer(id: number){

    this.loader.show();
      this.service.terminer(id).subscribe(
        (data) => {
            this.commande = data;
            this.retirerEvent.emit(id);
            this.commandeChange.emit(data);
            this.statusChange.emit("terminer");
            this.loader.hide();
            Swal.fire('Ok!', 'La commande ' + data.reference + ' est au statut terminé.', 'success');

        },
        error => {
          
        });
  }

  confirmLivree(id: number, ref: string) {
    Swal.fire({
      title: 'Etes vous sûre?',
      text: 'Vous êtes sur le point de déclarer la commande ' + ref + ' comme livrée',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Oui, livrer!',
      cancelButtonText: 'Annuler'
    }).then(result => {
      if (result.value) {
        this.livrer(id);
        
      }
    });
  }


  livrer(id: number){

      this.loader.show();
      this.service.livrer(id).subscribe(
        data => {
            this.commande = data;
            this.retirerEvent.emit(id);
            this.statusChange.emit("livrer");
            this.commandeChange.emit(data);
            this.loader.hide();
            Swal.fire('Ok!', 'La commande ' + data.reference + ' est au statut livré.', 'success');
        },
        error => {
          
        });
  }

  openLivraisonModal() {

    const modalRef = this.modalService.open(LivraisonWithPaiementComponent, { size: 'md', centered: true });
		modalRef.componentInstance.selectedCommande = this.commande;
    modalRef.componentInstance.statusChange.subscribe(val => this.statusChange.emit(val));
    modalRef.componentInstance.retirerEvent.subscribe(id => this.retirerEvent.emit(id));
    modalRef.componentInstance.commandeChange.subscribe(c => {
      this.commande = c;
      this.commandeChange.emit(c);
    });

  }

  openPaiementModal() {

    const modalRef = this.modalService.open(PaiementCommandeModalComponent, { size: 'md', centered: true });
		modalRef.componentInstance.selectedCommande = this.commande;
    //modalRef.componentInstance.statusChange.subscribe(val => this.statusChange.emit(val));
    //modalRef.componentInstance.retirerEvent.subscribe(id => this.retirerEvent.emit(id));
    modalRef.componentInstance.commandeChange.subscribe(c => {
      this.commande = c;
      this.commandeChange.emit(c);
    });

  }

  openPrioriteModal(){


    const modalRef = this.modalService.open(ChangePrioriteModalComponent, { size: 'md', centered: true });
		modalRef.componentInstance.commande = this.commande;

    modalRef.componentInstance.commandeChange.subscribe(c => {
      this.commande = c;
      this.commandeChange.emit(c);
      this.priorityChange.emit(c.priorite);
    });

  }

  getReliquat(){ 
    let sommePayee = this.commande.paiements.reduce((sum, pay) => sum + pay.montant, 0)
    return this.commande.cout - sommePayee;
  }

  getTotalPaiementsRecu(){ 
    return this.commande.paiements.reduce((sum, pay) => sum + pay.montant, 0)
  }
}
