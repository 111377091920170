import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from "src/environments/environment";



@Injectable({ providedIn: 'root' })
export class CoutureService {

    constructor(private http: HttpClient) { }

    search(searchForm, request) {
        const params = request;
        return this.http.post<any>(environment.BASE_URL + `/api/coutures/search`, searchForm, {params});
    }

    getTopCoutures() {
        return this.http.get<any>(environment.BASE_URL + `/api/coutures/top`);
    }

    getAll() {
        return this.http.get<any>(environment.BASE_URL + `/api/coutures`);
    }

    changeStatut(couture) {
        return this.http.post<any>(environment.BASE_URL + `/api/coutures/change-statut`, couture);
    }
  
}