import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Couture } from 'src/app/core/models/couture.model';
import { Personnel } from 'src/app/core/models/personnel.model';
import { CoutureService } from 'src/app/core/services/entities/couture.service';
import { PersonnelService } from 'src/app/core/services/entities/personnel.service';
import { LoaderService } from 'src/app/core/services/loader.service';

@Component({
  selector: 'app-form-en-couture',
  templateUrl: './form-en-couture.component.html',
  styleUrls: ['./form-en-couture.component.scss']
})
export class FormEnCoutureComponent implements OnInit {

  loading$ = this.loader.loading$;

  couture;
  refCommande;
  idCommande;
  dateLivraison;
  statut;

  enCoutureForm: FormGroup;


  personnel: Personnel[] = [];
  coupeurs: Personnel[] = [];
  piqueurs: Personnel[] = [];
  brodeurs: Personnel[] = [];

  submitStep = false;

  position;

  modeles;

  @Output()
  coutureChange : EventEmitter<Couture> = new EventEmitter<Couture>();

  constructor(public activeModal: NgbActiveModal, private personnelService: PersonnelService,
    private loader: LoaderService, public formBuilder: FormBuilder, private service: CoutureService
  ) { }

  ngOnInit(): void {

    let dateLivraisonPrevue =  this.dateLivraison.substring(0,10); 
    
    if(this.couture && this.couture.dateLivraisonPrevue){
      dateLivraisonPrevue = this.couture.dateLivraisonPrevue.substring(0,10);
    }

    this.enCoutureForm = this.formBuilder.group({
      modele: [this.couture.modele, null],
      broderie: [this.couture.broderie, null],
      dateLivraisonPrevue: [dateLivraisonPrevue, null],
      datePrete: [this.couture.datePrete? this.couture.datePrete.substring(0,10) : null, null],
      dateLivraisonEffective: [this.couture.dateLivraisonEffective? this.couture.dateLivraisonEffective.substring(0,10) : null, null],
      coupeurs: [this.couture.coupeurs?.map(p=>p.id), null],
      piqueurs: [this.couture.piqueurs?.map(p=>p.id), null],
      brodeurs: [this.couture.brodeurs?.map(p=>p.id), null],
      etapes: this.formBuilder.array(
        this.couture.etapes.map(etape => this.formBuilder.group({
          etapeCouture: [etape.etapeCouture, null],
          completed: [etape.completed, null]
        }))
         ),
    })

    if(this.enCoutureForm){

      if(this.statut == 'TERMINEE'){
        this.enCoutureForm.controls['datePrete'].setValidators([Validators.required]);
      }else if(this.statut == 'LIVREE'){
        this.enCoutureForm.controls['dateLivraisonEffective'].setValidators([Validators.required]);
      }
      
    }
    

    this.getAllPersonnel();
  }

  get form() {
    return this.enCoutureForm.controls;
  }

  getAllPersonnel() {
    this.loader.show();
		this.personnelService.list().subscribe(
        data => {
          this.personnel = data;
          this.piqueurs = this.personnel.filter(p => p.role == 'PIQUEUR' );
          this.coupeurs = this.personnel.filter(p => p.role == 'COUPEUR' );
          this.brodeurs = this.personnel.filter(p => p.role == 'BRODEUR' );
          this.loader.hide();}, 
        error => {
			    console.log(error);
          this.loader.hide();
		    }
		);

	}

  getAction(){

    if(this.statut == 'EN_COUTURE' && this.couture.statut == 'EN_COUTURE') return 'Enregistrer';
    if(this.statut == 'EN_COUTURE') return 'Mettre en couture';
    if(this.statut == 'TERMINEE') return '';
  }

  save(){


    this.loader.show();

    this.submitStep = true;

    if (this.enCoutureForm.invalid) {
      this.loader.hide();
      return;
    } else {

      let couture = this.enCoutureForm.value;

      couture.position = this.position;

      couture.idCommande = this.idCommande;

      couture.statut = this.statut

      this.service.changeStatut(couture).subscribe(
        data => {

          this.loader.hide();

          this.coutureChange.emit(data);

          this.activeModal.close();

        },
        error => {

          this.loader.hide();
        }
      );

      
    } 

  }



}
