<div class="modal-header">

  <h4 class="float-end font-size-16">Detail commande # {{commande.reference}}</h4>
  <button type="button" class="btn-close" aria-hidden="true" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body back-gray">

  <div class="row container-fluid">
    <div class="col-lg-12 col-xl-9  col-sm-12">
      <div class="card">
        <div class="card-body">

          <div class="row">
            <div class="col-6">
              <address>

                <h5 class="font-size-14"><i class="bx bx-user me-1 text-primary"></i> Client</h5>
                {{commande.client.nom}}<br>
                {{!(isAdmin || isBoutique)? '' : commande.client.telephone}}<br
                  *ngIf="(isAdmin || isBoutique)  && commande.client.telephone">
                {{commande.client.adresse}}<br *ngIf="commande.client.adresse">
                {{commande.client.email}}<br *ngIf="commande.client.email">

              </address>
            </div>

            <div class="col-6 text-sm-end">
              <address>
                <h5 class="font-size-14"><i class="bx bx bx-loader-circle me-1 text-primary"></i> Statut de la commande
                </h5>
                <p class="text-muted mb-0">
                <p class="text-muted mb-0">
                  <span class="badge badge-pill badge-soft-success font-size-11"
                    [ngClass]=" { 'badge-soft-warning': commande.statut === 'A_TRAITER',
                    'badge-soft-primary': commande.statut === 'EN_COUTURE','badge-soft-success':commande.statut === 'LIVREE', 
                    'badge-soft-danger': commande.statut === 'RETOUR','badge-soft-info':commande.statut === 'TERMINEE' }">
                    {{commande.statut | statut }}
                  </span>

                </p>

              </address>
            </div>
          </div>

          <div class="row" *ngIf="commande.priorite">

            <div class="col-6">

            </div>
            

            <div class="col-6 text-sm-end">
              <address>
                <h5 class="font-size-14"><i class="bx bx bx-up-arrow me-1 text-primary"></i> Priorité
                </h5>
                <p class="text-muted mb-0">
                <p class="text-muted mb-0">
                  <span class="badge badge-pill font-size-11"
                    [ngClass]=" { 'badge-soft-warning': commande.priorite === 'MOYENNE',
                    'badge-soft-danger': commande.priorite === 'HAUTE','badge-soft-info':commande.priorite === 'BASSE' }">
                    {{commande.priorite | titlecase }}
                  </span>

                </p>

              </address>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <address>
                <h5 class="font-size-14"><i class="bx bx-money me-1 text-primary"></i> Statut paiement
                </h5>
                <p class="text-muted mb-0">


                  <span class="badge badge-pill badge-soft-success font-size-11"
                    [ngClass]=" { 'badge-soft-warning': commande.statutPaiement === 'A_PAYER',
                  'badge-soft-success':commande.statutPaiement === 'PAYEE','badge-soft-info':commande.statutPaiement === 'ACOMPTE' }">
                    {{commande.statutPaiement | statut }}
                  </span>
                </p>
              </address>
            </div>

            <div class="col-6 text-sm-end">
              <address>
                <h5 class="font-size-14"><i class="bx bx-calendar-check me-1 text-primary"></i> Date de la commande
                </h5>
                <p class="text-muted mb-0"> {{commande.dateCommande | date:'d MMM y'}}</p>

              </address>
            </div>
          </div>
          <div class="row">
            <div class="col-6 mt-3">
              <address *ngIf="(isAdmin || isBoutique)  && commande.paiements.length > 0">

                <h5 class="font-size-14"><i class="bx bx-money me-1 text-primary"></i> Paiements
                </h5>

                <p *ngFor="let pay of commande.paiements">{{pay.montant | currency : monnaie : 'symbol': '1.0-0': 'fr'}}
                  {{pay.moyenPaiement | statut }} le
                  {{pay.date | date:'d MMM y'}} </p>

                <p *ngIf="commande.paiements.length > 1"><b>Total reçu:</b> {{getTotalPaiementsRecu() | currency : monnaie : 'symbol': '1.0-0': 'fr'}}  </p>

              </address>
            </div>

            <div class="col-6 text-sm-end">
              <address>
                <h5 class="font-size-14"><i class="bx bx-calendar-check me-1 text-primary"></i> Date livraison prévue
                </h5>
                <p class="text-muted mb-0"> {{commande.dateLivraison | date:'d MMM y'}}</p>

              </address>
            </div>

          </div>

          <div class="row" >
            <div class="col-6 mt-3">

              <address *ngIf="(isAdmin || isBoutique)  && commande.statutPaiement === 'ACOMPTE' ">

                <h5 class="font-size-14"><i class="bx bx-money me-1 text-primary"></i> Reliquat paiement
                </h5>

                <p>{{getReliquat() | currency : monnaie : 'symbol': '1.0-0': 'fr'}}</p>

              </address>
              
            </div>
            <div class="col-6 text-sm-end">
              <address *ngIf="commande.dateLivraisonEffective">
                <h5 class="font-size-14"><i class="bx bx-calendar-check me-1 text-primary"></i> Date livraison effective
                </h5>
                <p class="text-muted mb-0"> {{commande.dateLivraisonEffective | date:'d MMM y'}}</p>

              </address>
            </div>
            
          </div>
          
          <div class="row">
            <div *ngIf="commande.notes" class="col-12 mt-3">
              <address>
                <strong>Notes:</strong><br>
                {{commande.notes}}<br><br>
              </address>
            </div>
          </div>
          <div class="py-2 mt-3">

            <h5 class="font-size-18">Liste des articles</h5>
          </div>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>

                  <th>Tissu</th>
                  <th>Client</th>
                  <th>Designation</th>
                  <th>Statut</th>
                  <th *ngIf="isAdmin || isBoutique" class="text-end">Cout</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let article of commande.coutures; let i=index">

                  <td class="align-middle">
                    
                    <img data-bs-toggle="tooltip" data-bs-placement="top" title="Cliquer pour agrandir" *ngIf="article.photoTissu" (click)="openImage(article.photoTissu)" class="avatar-md" src="{{ article.photoTissu}}" alt="Photo Tissu couture">
                      
                    <img *ngIf="false" src="assets/images/no-image.jpg" alt="product-img" title="product-img" class="avatar-md" />
                    <div *ngIf="!article.photoTissu && article.couleur" class="box" [style.background-color]="article.couleur"> <span class="align-middle">{{article.typeTissu}}</span> </div>
                    <div *ngIf="!article.photoTissu && !article.couleur && article.typeTissu">{{article.typeTissu}}</div>
                  </td>

                  <td class="align-middle">
                    {{article.nomProprietaire}}
                  </td>

                  <td class="align-middle">
                    Type: <span class="fw-medium">{{article.typeVetement}}</span>
                    <p *ngIf="article.modele" class="mb-0">Modele : <span class="fw-medium">{{article.modele}}</span></p>
                    <p *ngIf="article.broderie" class="mb-0">Broderie : <span class="fw-medium">{{article.broderie}}</span></p>
                    
                  </td>

                  <td class="align-middle">
                      <span class="badge badge-pill badge-soft-success font-size-11" [ngClass]=" { 'badge-soft-warning': article.statut === 'A_TRAITER',
                      'badge-soft-primary': article.statut === 'EN_COUTURE','badge-soft-success':article.statut === 'LIVREE', 
                      'badge-soft-danger': article.statut === 'RETOUR','badge-soft-info':article.statut === 'TERMINEE' }">
                          {{ article.statut | statut }} 
                      </span>
                  </td>

                  <td  *ngIf="isAdmin || isBoutique" class="text-end align-middle">{{article.cout | currency : monnaie : 'symbol': '1.0-0':
                    'fr'}}
                  </td>
                  
                  <td class="align-middle">

                      <ul class="list-unstyled hstack gap-1 mb-0">
                        <li data-bs-toggle="tooltip" data-bs-placement="top" title="Voir details">
                            <a (click)="viewCouture(article, commande.reference, i)" href="javascript:void(0);"  class="btn btn-sm btn-primary"><i class="mdi mdi-eye-outline"></i></a>
                        </li>
                        <li *ngIf="false" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit">
                            <a href="javascript:void(0);" class="btn btn-sm btn-soft-info" ><i class="mdi mdi-pencil-outline"></i></a>
                        </li>
                        <li *ngIf="false" data-bs-toggle="tooltip" data-bs-placement="top" title="Delete">
                            <a href="javascript:void(0);" data-bs-toggle="modal" class="btn btn-sm btn-soft-danger"><i class="mdi mdi-delete-outline"></i></a>
                        </li>
                      </ul>

                    </td>
                </tr>


                <tr *ngIf="isAdmin || isBoutique">
                  <td [attr.colspan]="4" class="border-0 text-end">
                    <strong>Total</strong>
                  </td>
                  <td class="border-0 text-end">
                    <h4 class="m-0">{{ commande.cout | currency : monnaie : 'symbol': '1.0-0': 'fr'}}</h4>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>
      </div>
    </div>
    <div class="col-xl-3 col-lg-12 col-sm-12">


      <div class="card">
        <div class="card-body">
          <h4 class="card-title mb-5">Activités</h4>
          <ul class="verti-timeline list-unstyled" *ngIf="commande.activites">
            <li *ngFor="let activity of commande.activites" class="event-list">
              <div class="event-timeline-dot">
                <i class="bx bx-right-arrow-circle font-size-18"></i>
              </div>
              <div class="media">
                <div class="mr-3">
                  <h5 class="font-size-10">{{activity.date | date :'dd MMM, YYYY HH:mm' }} <i
                      class="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2"></i>
                  </h5>
                </div>
                <div class="media-body">
                  <div>
                    {{activity.description}}
                    <p>
                      <small class="text-muted">{{activity.acteur}}</small>
                    </p>
                  </div>
                </div>
              </div>
            </li>

          </ul>
          <div class="text-center mt-4"></div>
        </div>
      </div>



    </div>
  </div>
  <!-- end row -->



</div>
<div class="modal-footer">

  <button *ngIf="isAdmin || isBoutique" class="btn btn-outline-secondary" routerLink="/commandes/facture/{{commande.id}}">Voir
    facture</button>
  <button *ngIf="commande.statut=='A_TRAITER' || commande.statut=='EN_COUTURE' "
    routerLink="/commandes/en-couture/{{commande.id}}" class="btn btn-primary">En couture</button>
  <button *ngIf="commande.statut=='A_TRAITER' || commande.statut=='EN_COUTURE' "
    (click)="openChangeStatut(commande.id, 'TERMINEE')" class="btn btn-success">Terminer</button>
  <button *ngIf="commande.statut=='TERMINEE'  && commande.statutPaiement == 'PAYEE'"
    (click)="openChangeStatut(commande.id, 'LIVREE')" class="btn btn-success">Livrer</button>
  <button *ngIf="commande.statut=='TERMINEE' && commande.statutPaiement != 'PAYEE'" (click)="openLivraisonModal()"
    class="btn btn-success">Livrer</button>
  <button *ngIf="commande.statutPaiement != 'PAYEE'" (click)="openPaiementModal()"
    class="btn btn-outline-success">Enregistrer un paiement</button>
  <button class="btn btn-outline-primary"
    *ngIf="(isBoutique && (commande.statut=='EN_COUTURE' || commande.statut=='A_TRAITER')) || isAdmin"
    routerLink="/commandes/modification/{{commande.id}}">Modifier</button>
  
  <button class="btn btn-outline-warning"
    *ngIf="commande.statut!='LIVREE'"
    (click)="openPrioriteModal()">Priorité</button>
  <button class="btn btn-outline-danger" (click)="confirmDelete(commande.id)"
    *ngIf="isAdmin || (isBoutique && tenant !='colle-sow-ardo')">Supprimer</button>
  <button class="btn btn-secondary" (click)="activeModal.close('Close click')">Fermer</button>
</div>