import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgbDropdownModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';

import { StatComponent } from './stat/stat.component';
import { TransactionComponent } from './transaction/transaction.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { StatsCommandeStatutComponent } from './stats-commande-statut/stats-commande-statut.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { StatsPaiementsRecusComponent } from './stats-paiements-recus/stats-paiements-recus.component';

@NgModule({
  declarations: [StatComponent, TransactionComponent, StatsCommandeStatutComponent, StatsPaiementsRecusComponent],
  imports: [
    CommonModule,
    NgbModalModule,
    RouterModule,
    FormsModule,
    Ng2SearchPipeModule,
    ReactiveFormsModule,
    NgbDropdownModule,
    NgApexchartsModule,
  ],
  exports: [StatComponent, TransactionComponent, StatsCommandeStatutComponent, StatsPaiementsRecusComponent]
})
export class WidgetModule { }
