import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from "src/environments/environment";
import { Modele } from '../../models/modele.model';


@Injectable({ providedIn: 'root' })
export class ModeleService {

    constructor(private http: HttpClient) { }

    getAllPageable(request) {
        const params = request;
        return this.http.get<Modele[]>(environment.BASE_URL + `/api/modeles`, {params});
    }

    getAll() {
        return this.http.get<Modele[]>(environment.BASE_URL + `/api/modeles`);
    }

    getStats() {
        return this.http.get<any[]>(environment.BASE_URL + `/api/modeles/stats`);
    }

    add(modele: any) {
        return this.http.post<Modele>(environment.BASE_URL + `/api/modeles`, modele);
    }

    edit(idModele: number, modele: any) {
        return this.http.put<Modele>(environment.BASE_URL + `/api/modeles/${idModele}`, modele);
    }

    delete(idModele: number) {
        return this.http.delete<void>(environment.BASE_URL + `/api/modeles/${idModele}`);
    }

    getById(idModele: number) {
        return this.http.get<Modele>(environment.BASE_URL + `/api/modeles/${idModele}`);
    }

  
}